<template>
    <div class="w-100 h-100">
        <div id="sceneview"></div>
        <el-card v-show="toolbarShow" class="box-card" id="card">
            <div slot="header" class="clearfix">
                <span>图层显示</span>
                <el-button style="float: right;padding:0" type="text">
                    <i class="el-icon-help" @click="toolbarShow = false" style="font-size: 24px;color: #e2d849;"></i>
                </el-button>
            </div>
            <div>
                <el-tree default-expand-all ref="tree" style="padding:10px 0important" :data="serveList" show-checkbox
                    @check="getCheckedNodes">
                </el-tree>
            </div>
        </el-card>
        <i v-show="!toolbarShow" class="el-icon-setting" @click="toolbarShow = true"
            style="font-size: 24px;position: fixed;top: 78px;right: 22px;cursor:pointer;z-index: 998;color: #e2d849;"></i>
    </div>
</template>

<script>
    import { loadModules } from 'esri-loader'

    export default {
        name: 'App',
        components: {

        },
        data() {
            return {
                toolbarShow: true,
                //全部图层
                serveList: [
                    {
                        label: '150团',
                        url: '',
                        children: [
                            //{
                            // label:'底图',
                            // serveName:'baseMap',
                            // url:'https://gis.lc-times.cn/server/rest/services/Basemap/Google影像/MapServer'
                            // },
                            // {
                            // label:'150团',
                            // serveName:'tuan_150',
                            // url:'https://gis.lc-times.cn/server/rest/services/T150/tj150/MapServer'
                            // },
                            {
                                label: '叶面积指数',
                                serveName: 'ymjzs',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/叶面积指数_2021/MapServer'
                            },
                            {
                                label: '地表温度',
                                serveName: 'dbwd',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/地表温度_2021/MapServer'
                            },
                            {
                                label: '干度指数',
                                serveName: 'gdzs',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/干度指数_2021/MapServer'
                            },
                            {
                                label: '植被覆盖度',
                                serveName: 'zbfgd',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/植被覆盖度_2021/MapServer'
                            },
                            {
                                label: '植被长势',
                                serveName: 'zbzs',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/植被长势_2021/MapServer'
                            },
                            {
                                label: '水分指数',
                                serveName: 'sfzs',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/水分指数_2021/MapServer'
                            },
                            {
                                label: '湿度指数',
                                serveName: 'sdzs',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/湿度指数_2021/MapServer'
                            },
                            {
                                label: '花青素指数',
                                serveName: 'hqszs',
                                url: 'https://gis.lc-times.cn/server/rest/services/T150/花青素指数_2021/MapServer'
                            },
                        ],

                    }
                ],
                //已选图层
                imageList: [],
            }
        },
        methods: {
            createSceneView: function () {
                window.console.log(this.imageList)
                let options = {
                    url: 'https://js.arcgis.com/4.22/',
                    css: 'https://js.arcgis.com/4.22/esri/themes/light/main.css'
                };
                loadModules(["esri/Map", "esri/views/MapView", "esri/layers/TileLayer", "esri/layers/MapImageLayer"], options).then(([Map, MapView, TileLayer, MapImageLayer]) => {
                    let map = new Map({
                        center: [86.046999, 44.316137],
                    });

                    let layer = new TileLayer({
                        url: "https://gis.lc-times.cn/server/rest/services/Basemap/Google影像/MapServer"
                    })

                    map.layers.add(layer)

                    // let a = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/landcover/MapServer"
                    // })
                    // let b = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/tj150/FeatureServer"
                    // })
                    //150团轮廓图
                    let t150 = new MapImageLayer({
                        url: "https://gis.lc-times.cn/server/rest/services/T150/tj150/MapServer"
                    })
                    map.add(t150)
                    window.console.log(this.imageList)
                    for (let i = 0; i < this.imageList.length; i++) {
                        if (this.imageList[i].url != '') {
                            map.add(new MapImageLayer({
                                url: this.imageList[i].url
                            }))
                        }
                    }
                    // let d = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/叶面积指数_2021/MapServer"
                    // })
                    // let e = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/地表温度_2021/MapServer"
                    // })
                    // let f = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/干度指数_2021/MapServer"
                    // })
                    // let g = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/植被覆盖度_2021/MapServer"
                    // })
                    // let h = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/植被长势_2021/MapServer"
                    // })
                    // let i = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/水分指数_2021/MapServer"
                    // })
                    // let j = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/湿度指数_2021/MapServer"
                    // })
                    // let k = new MapImageLayer({
                    //   url:"https://gis.lc-times.cn/server/rest/services/T150/花青素指数_2021/MapServer"
                    // })
                    //map.add(a);
                    //map.add(b);
                    //map.add(c);
                    // map.add(d);
                    // map.add(e);
                    // map.add(f);
                    // map.add(g);
                    // map.add(h);
                    // map.add(i);
                    // map.add(j);
                    // map.add(k)
                    window.console.log(map)
                    // window.console.log(g)
                    let view = new MapView({
                        container: "sceneview",
                        map: map,
                        zoom: 11,
                        center: [86.024765, 45.041873],
                    });
                    window.console.log(view)
                })
            },
            // handleCheckChange(data){
            //     window.console.log(data)
            //     this.imageList = data
            // },
            getCheckedNodes() {
                window.console.log(this.$refs.tree.getCheckedNodes());
                this.imageList = this.$refs.tree.getCheckedNodes()
                setTimeout(() => {
                    this.createSceneView()
                }, 500)
            },
        },
        mounted() {
            this.createSceneView();
        }
    }
</script>

<style>
    #sceneview {
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
    }

    #card {
        position: absolute;
        top: 60px;
        right: 0;
        width: 240px;
    }

    .el-tree-node__content {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>